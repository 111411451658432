"use client";

import React, { type ReactNode } from "react";
import { LDProvider } from "launchdarkly-react-client-sdk";
import { randomUUID } from "crypto";

export default function LaunchDarklyProvider({
  children,
  region,
  clientSideId
}: Readonly<{
  children: ReactNode;
  region: string;
  clientSideId: string;
}>) {
  const impressionId = randomUUID;
  const clientSideContext = {
    kind: "multi",
    serverContext: {
      key: "nextjs-app-component"
    },

    user: {
      key: impressionId,
      country: region,
      anonymous: true
    }
  };

  return (
    <LDProvider clientSideID={clientSideId} context={clientSideContext}>
      {" "}
      {children}
    </LDProvider>
  );
}
